import EditUser from 'pages/account/Edit';
import Login from 'pages/auth/Login';
import PasswordRequest from 'pages/auth/PasswordRequest';
import Register from 'pages/auth/Register';
import ResetPassword from 'pages/auth/ResetPassword';
import ResetRequest from 'pages/auth/ResetRequest';

import Companies from 'pages/Companies';
import Dashboard from 'pages/Dashboard';
import KybRequest from 'pages/KYB';
import Businesses from 'pages/Businesses';
import PageNotFound from 'pages/PageNotFound';
import Transactions from 'pages/Transactions';
import Budgets from 'pages/Budgets';
import Users from 'pages/Users';
import Profile from './pages/Profile';
import DemoRequest from 'pages/DemoRequest';
import DocumentUpload from 'pages/KYB/DocumentUpload';
import CompanyDetails from 'pages/Companies/company-details';

export default [
  {
    exact: true,
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    exact: true,
    path: '/forgot-password',
    name: 'Forgot Password',
    component: PasswordRequest,
  },
  {
    exact: true,
    path: '/reset_link/:hashVal',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    exact: true,
    path: '/reset-request',
    name: 'Reset Request',
    component: ResetRequest,
  },
  {
    exact: true,
    path: '/user/edit',
    name: 'EditUser',
    isPrivate: true,
    headerHide: true,
    component: EditUser,
  },
  {
    exact: true,
    path: '/companies',
    name: 'Companies',
    component: Companies,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/compliance/:code',
    name: 'Company Details',
    component: CompanyDetails,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/compliance',
    name: 'Compliance',
    component: KybRequest,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/compliance/document-upload',
    name: 'Document upload',
    component: DocumentUpload,
    isPrivate: true,
  },

  {
    exact: true,
    path: '/employees',
    name: 'Employees',
    component: Users,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/budgets',
    name: 'Budgets',
    component: Budgets,
    isPrivate: true,
  },
  {
    exact: true,
    path: '/demo-request',
    name: 'Demo Request',
    component: DemoRequest,
    isPrivate: true,
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: PageNotFound,
    isPrivate: true,
  },
];
