import { HintAlertIcon, ShieldAlertIcon } from 'assets/icons';
import ModalContainer from 'components/ModalContainer';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import { toastError } from 'components/UI/toast';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
// import { getCompany, getState } from 'redux/actions/CompaniesAction';
import {
  getInternationalFormat,
  idType,
  states,
  validateNigeriaNumber,
} from 'utils/helper';
// import '../../styles.scss';

const BusinessOwner = ({ directors, setDirectors }) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [businessOwner, setBusinessOwner] = useState({});
  const [businessOwnerDocument, setBusinessOwnerDocument] = useState({});

  const dispatch = useDispatch();
  const {
    getCountry: { data: countryData = {} },
  } = useSelector(({ companies }) => companies);

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBusinessOwner({
      ...businessOwner,
      internationalFormat,
      localFormat,
      countryCode,
    });
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setBusinessOwner({ ...businessOwner, [name]: value });
  };

  const handleChangeDate = (value) => {
    setBusinessOwner({
      ...businessOwner,
      dob: dayjs(value).format('YYYY-MM-DD'),
    });
  };

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData
        ?.filter((item) => item.name === 'NIGERIA')
        ?.map(({ code: value, name: label }) => ({
          value,
          label,
          isDisabled: label !== 'NIGERIA' ? true : false,
        }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, []);

  // useEffect(() => {
  //   if (!countryData?.length) dispatch(getCountries());
  //   if (companyData?.code && !directors?.length)
  //     dispatch(getInvitedDirectors(companyData?.code));
  // }, [companyData?.code]);

  const getCountryStates = async (value) => {
    // dispatch(getState(value.value));
  };

  useEffect(() => {
    if (directors) {
      console.log(directors);
      const { directorsData } = directors;

      const getDocuments = (key) => {
        return directorsData?.documents.find((item) => item.type === key);
      };
      setBusinessOwner({
        ...businessOwner,
        ...directorsData,
        bvn: getDocuments('bvn').number,
        idNumber: getDocuments('bvn').number,
        idType: idType.find((item) => item.value === 'nin'),
        address: directorsData?.address?.street,
        city: directorsData?.address?.city,
        state: {
          label: directorsData?.address?.state,
          value: directorsData?.address?.state,
        },
        country: {
          label: directorsData?.address?.country,
          value: directorsData?.address?.country,
        },
        localFormat: directorsData?.phoneNumber?.localFormat || '',
        countryCode: directorsData?.phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(
            directorsData?.phoneNumber?.countryCode,
            directorsData?.phoneNumber?.localFormat,
          ) || '',
      });
    }
  }, [directors]);

  const handleUploadDirectorInfo = () => {
    if (!businessOwner.percentageOwned)
      return toastError('Please enter percentage owned');
    if (+businessOwner.percentageOwned < 5)
      return toastError('Percentage owned must be at least 5%');
    if (!businessOwner.firstName) return toastError('Please enter first name');
    if (!businessOwner.lastName) return toastError('Please enter last name');
    if (!businessOwner.dob) return toastError('Please enter date of birth');
    if (!businessOwner.bvn) return toastError('Please enter BVN');
    if (!businessOwner.email) return toastError('Please enter email');
    if (
      !isValidPhoneNumber(String(businessOwner?.internationalFormat)) ||
      !validateNigeriaNumber(String(businessOwner?.internationalFormat))
    )
      return toastError('Please enter a valid phone number');

    if (!businessOwner.country) return toastError('Please select country');
    if (!businessOwner.state) return toastError('Please select state');
    if (!businessOwner.address) return toastError('Please enter address');
    if (!businessOwnerDocument.utilityBill)
      return toastError('Please upload a copy of the proof of address');

    if (!businessOwner.idType) return toastError('Please select ID type');
    if (!businessOwner.idNumber)
      return toastError(`Please enter ${businessOwner.idType.value} number`);
    if (
      (businessOwner.idNumber.length < 11 || businessOwner.idNumber.length > 11) &&
      businessOwner.idType.value === 'nin'
    )
      return toastError('ID number must be 11 digit');

    if (!businessOwnerDocument.idCopy)
      return toastError('Please upload a copy of the ID');

    const payload = {
      // code: companyData?.code,
      firstName: businessOwner.firstName,
      lastName: businessOwner.lastName,
      email: businessOwner.email,
      dob: businessOwner.dob,
      bvn: businessOwner.bvn,
      percentageOwned: businessOwner.percentageOwned,
      phoneNumber: {
        countryCode: businessOwner.countryCode,
        localFormat: businessOwner.localFormat,
      },
      address: {
        country: businessOwner.country.value,
        state: businessOwner.state?.value,
        city: businessOwner.city,
        street: businessOwner.address,
      },
      documents: {
        idType: businessOwner.idType.value,
        idNumber: businessOwner.idNumber,
        idCopy: businessOwnerDocument.idCopy,
        utilityBill: businessOwnerDocument.proofOfAddress,
      },
    };
    // dispatch(uploadDirectorInfo(payload));
  };

  return (
    <Modal show={!!directors} centered dialogClassName="custom-dialog">
      <ModalContainer
        lg
        onCancel={() => setDirectors(undefined)}
        onConfirm={handleUploadDirectorInfo}
        // loading={directorLoading}
        disabled
        actionBtnText="Save"
        title="Add business owner"
        subTitle="Please provide information for anyone who owns 10% or more of the business."
      >
        <div className="border-bottom bg-transparent pb-3 text-white">
          <Row className="mb-2">
            <CustomInput
              type="text"
              label="Percentage owned"
              placeholder="Ex. 10%"
              name="percentageOwned"
              onChange={handleInputChange}
              value={businessOwner.percentageOwned}
              isNumber={false}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength="3"
            />
          </Row>
        </div>

        <div className="pt-4 border-bottom bg-transparent pb-3">
          <h6 className="text-md p-0 m-0 fw-semibold text-white">Personal details</h6>

          <Row className="mb-2 mt-2 align-items-center">
            <CustomInput
              type="text"
              label="First name"
              placeholder="Enter first name"
              name="firstName"
              onChange={handleInputChange}
              value={businessOwner.firstName}
              md={6}
            />

            <CustomInput
              type="text"
              label="Last name"
              placeholder="Enter last name"
              name="lastName"
              onChange={handleInputChange}
              value={businessOwner.lastName}
              md={6}
            />
          </Row>

          <Row className="mb-2">
            <CustomDatePicker
              label="Date of birth"
              name="dob"
              onChange={handleChangeDate}
              maxDate={addYears(new Date(), -18)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="DD / MM / YYYY"
              selected={businessOwner.dob && dayjs(businessOwner.dob).toDate()}
            />
          </Row>

          <Row className="mb-2">
            <CustomInput
              type="text"
              label="Bank Verification Number (BVN)"
              placeholder="11234567890"
              name="bvn"
              onChange={handleInputChange}
              value={businessOwner.bvn}
              maxLength="11"
            />
            <Form.Text className="text-white gap-1 d-flex align-items-center">
              <ShieldAlertIcon />
              <span className="text-xs">
                Your BVN is used for identify verification and secured with encryption.
              </span>
            </Form.Text>
          </Row>
        </div>

        <div className="pt-4 border-bottom bg-transparent pb-3">
          <h6 className="text-md p-0 m-0 fw-semibold text-white">Contact information</h6>

          <Row className="mb-2 mt-2">
            <CustomPhoneNumberInput
              label="Phone number"
              placeholder="Enter your mobile number"
              onChange={(localFormat, international, countryCode) =>
                handlePhoneNumberChange(localFormat, international, countryCode)
              }
              value={businessOwner.internationalFormat}
            />
          </Row>

          <Row className="mb-2">
            <CustomInput
              type="text"
              label="Enter your email"
              placeholder="joe@mail.com"
              name="email"
              onChange={handleInputChange}
              value={businessOwner.email}
            />
          </Row>

          <Row className="mb-2">
            <CustomSelect
              label="Country"
              name="country"
              placeholder="Select country"
              onChange={(value) => {
                setBusinessOwner({ ...businessOwner, country: value });
                getCountryStates(value);
              }}
              value={businessOwner.country}
              options={generateCountry}
              isCountry
            />
          </Row>
          <Row className="mb-2">
            <CustomSelect
              label="State"
              name="state"
              placeholder="Select country"
              onChange={(value) => {
                setBusinessOwner({ ...businessOwner, state: value });
              }}
              value={businessOwner.state}
              options={generateState}
            />
          </Row>

          <Row className="mb-2">
            <CustomInput
              label="City"
              name="city"
              placeholder="Enter city"
              onChange={handleInputChange}
              value={businessOwner.city}
            />
          </Row>

          <Row className="mb-2">
            <CustomInput
              label="Home address"
              name="address"
              placeholder="Enter home address"
              onChange={handleInputChange}
              value={businessOwner.address}
            />
          </Row>

          <Row className="mb-2">
            {/* <ServerFileUpload
              label="Proof of Address"
              supportType="JPEG, PNG, and PDF formats, up to 5 MB."
              uploadedFile={businessOwnerDocument.utilityBill}
              onChange={({ assetCode } = {}) =>
                setBusinessOwnerDocument({
                  ...businessOwnerDocument,
                  utilityBill: assetCode,
                })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
              name={`owner-proof-address`}
            /> */}

            <Form.Text className="text-white gap-1 d-flex align-items-start flex-column">
              <div className="p-0 m-0 d-flex gap-1 align-items-start pb-2 text-xs">
                <div>
                  <HintAlertIcon />
                </div>
                Upload a copy of your bank statement, utility bill, phone bill, tax
                assessment, or any government-issued document.
              </div>

              <ul className="py-0 my-0 text-xs">
                <li> All documents must be less than 3 months old</li>
                <li>
                  And must include the name of the identified individual or business.
                </li>
              </ul>
            </Form.Text>
          </Row>
        </div>

        <div className="pt-4 pb-3">
          <h6 className="text-md p-0 m-0 fw-semibold text-white">
            Identity verification
          </h6>

          <Row className="mb-2 mt-2">
            <CustomSelect
              label="Document type"
              name="idType"
              placeholder="Select document type"
              onChange={(value) => setBusinessOwner({ ...businessOwner, idType: value })}
              value={businessOwner.idType}
              options={idType}
            />
          </Row>

          <Row className="mb-2">
            <CustomInput
              type="text"
              label="ID number"
              placeholder="11234567890"
              name="idNumber"
              onChange={handleInputChange}
              value={businessOwner.idNumber}
            />
          </Row>

          <Row className="mb-2">
            {/* <ServerFileUpload
              label="Upload ID"
              supportType="JPEG, PNG, and PDF formats, up to 5 MB."
              uploadedFile={businessOwnerDocument.utilityBill}
              onChange={({ assetCode } = {}) =>
                setBusinessOwnerDocument({
                  ...businessOwnerDocument,
                  idCopy: assetCode,
                })
              }
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
              name={`owner-doc-id`}
            /> */}
          </Row>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default BusinessOwner;
