import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import KybModal from 'components/kybModal';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getKybRequests } from 'redux/actions/KybRequestsAction';
import { buildKYBTableData } from 'utils/helper';
import { columnsKybRequest } from 'utils/mockData';
import './style.less';
import { useDebounce } from 'hooks/useDebounce';
import CustomButton from 'components/UI/CustomButton';

const KybRequest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectKyb, setSelectKyb] = useState(null);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const query = new URLSearchParams(location.search);
  const payerCode = query.get('search');
  const [filterData, setFilterData] = useState([...CurrencyType]);
  const {
    getKybRequest: { data: { meta = {} } = {}, data = {}, loading, success },
  } = useSelector(({ Kybrequest }) => Kybrequest);
  // const {
  //   getReimbursement: { data: rData },
  // } = useSelector(({ reimbursement }) => reimbursement);
  const { page, total, hasMore, perPage } = meta;
  const { companies = [] } = data;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch(getKybRequests());
  }, []);

  const handleRowClick = (row) => {
    push(`/compliance/${row?.kybData?.code}`);
  };

  useEffect(() => {
    if (meta) {
    }
  }, [meta]);

  const handleFilter = (query) => {
    const { status: s, currency: c, beneficiary: b, search } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const user = b ? b.toString() : undefined;

    dispatch(
      getKybRequests({
        status,
        currency,
        user,
        search,
      }),
    );
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([...CurrencyType]);
  };

  const handleNextPage = () => {
    if (!hasMore) return;
    dispatch(getKybRequests({ page: parseInt(page, 10) + 1, ...query }));
  };

  const handlePreviousPage = () => {
    dispatch(getKybRequests({ page: parseInt(page, 10) - 1, ...query }));
  };

  const handleDocumentUpload = () => {
    push('/compliance/document-upload');
  };

  const show = !!companies?.length;

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div className="kybrequest-wrapper">
      <TopBar
        headerText={'Compliance'}
        number={companies?.length > 0 && companies?.length}
        addButton
        addActionButton="Document upload"
        toggleActionButton={handleDocumentUpload}
      />

      <TopBar
        showFilter={show}
        addOnClick={toggleHandler}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        searchVal={search}
        setSearchVal={setSearch}
        showBarSearch={show}
        handleFilterApply={handleFilter}
        withDate
        dateTitle="Transaction Date"
        clearFilters={clearFilters}
        showRetry={false}
      />

      {!companies?.length ? (
        <div className="innerWrapper">
          <NoData
            headerText="No submissions yet"
            bodyText="The requests will be listed here"
            onClickHandler={toggleHandler}
            withButton={false}
          />
        </div>
      ) : (
        <>
          <Container className="pt-3">
            <Row>
              <Col xs={12} className="border border-dark rounded-3">
                <Table
                  columns={columnsKybRequest}
                  data={buildKYBTableData(companies)}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  currentPage={page}
                  totalPage={Math.ceil(total / perPage)}
                  nextPage={handleNextPage}
                  previousPage={handlePreviousPage}
                />
              </Col>
            </Row>
          </Container>
          <KybModal selectKyb={selectKyb} setSelectKyb={setSelectKyb} />
        </>
      )}
    </div>
  );
};

export default KybRequest;
